/**
 * Download text content with JavaScript
 *
 * @param {string} data The content within the downloaded file
 * @param {object} [filename="download.txt"] Name (with extension) for the downloaded file
 */

export default function download(data, filename = 'download.txt') {
  if (data == null) return;

  if (typeof data === 'object') {
    data = JSON.stringify(data, null, '\t');
  }

  const link = document.createElement('a');
  link.setAttribute(
    'href',
    `data:application/txt;charset=utf-8,${encodeURIComponent(data)}`
  );
  link.setAttribute('download', filename);
  link.click();
}
